import {Close} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";
import React, {useState} from "react";
import MyIcon from "../../MyIcon";
import SuccessAbortButtons from "../../SuccessAbortButtons";
import MultiSelect from "../../MultiSelect";
import {Filters} from "./types";
import {MultiSelectValues} from "../../MultiSelect/types";
import {
  getTitleTypographyStyles,
  getCloseIconStyles,
  getCloseIconContainerStyles,
  getDescriptionTypographyStyles,
  getMultiSelectStyles,
  getContainerStyles,
  getButtonSx,
  getLabelStyles,
} from "./styles";

interface FilterProps {
  closeModal: () => void;
  title?: string;
  description?: string;
  filters?: Filters;
  abortButtonName: string;
  successButtonName: string;
  initialFilters: MultiSelectValues;
  onSuccess: (selectedFilters: MultiSelectValues) => void;
  onAbort: () => void;
  titleStyles?: React.CSSProperties;
  descriptionStyles?: React.CSSProperties;
}

function Filter({
  closeModal,
  title,
  description,
  filters,
  abortButtonName,
  successButtonName,
  titleStyles,
  descriptionStyles,
  onAbort,
  onSuccess,
  initialFilters,
}: FilterProps) {
  const [tempSelectedFilters, setTempSelectedFilters] = useState(initialFilters);

  return (
    <Box>
      <Box sx={{mb: 3}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Typography sx={getTitleTypographyStyles(titleStyles)}>{title}</Typography>
          <MyIcon
            containerProps={{
              sx: getCloseIconContainerStyles(),
            }}
            Icon={() => <Close sx={getCloseIconStyles()} onClick={closeModal} />}
            aria-label="Close Modal"
          />
        </Box>
        <Typography sx={getDescriptionTypographyStyles(descriptionStyles)}>
          {description}
        </Typography>
      </Box>
      <Box>
        {filters?.map(filter => {
          return (
            <MultiSelect
              key={filter.label}
              name={filter.name}
              label={filter.label}
              options={filter.options}
              selectedFilters={tempSelectedFilters}
              setSelectedFilters={setTempSelectedFilters}
              SelectProps={{sx: getMultiSelectStyles()}}
              labelStyles={getLabelStyles() as any}
              containerStyles={getContainerStyles()}
            />
          );
        })}
      </Box>
      <SuccessAbortButtons
        onAbort={onAbort}
        onSuccess={() => onSuccess(tempSelectedFilters)}
        abortButtonName={abortButtonName ?? ""}
        successButtonName={successButtonName ?? ""}
        buttonSx={getButtonSx()}
      />
    </Box>
  );
}

export default Filter;
