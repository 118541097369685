import {UseQueryOptions, UseQueryResult, useQuery} from "@tanstack/react-query";
import {
  Appointment,
  AppointmentIdPaylod,
  GetAppointmentListParams,
} from "../../api/appointment/appointment.interface";
import {getAppointmentDetails, getAppointmentList} from "../../api/appointment/query";
import {ListResponse} from "../../shared/interfaces";
import {APPOINTMENT_LIST_QUERY_KEY} from "../constants";

export const useGetAppointmentListQuery = (
  params: GetAppointmentListParams,
  options?: Omit<UseQueryOptions<ListResponse<Appointment>, Error>, "queryKey">,
): UseQueryResult<ListResponse<Appointment>, Error> => {
  return useQuery<ListResponse<Appointment>, Error>({
    queryKey: [APPOINTMENT_LIST_QUERY_KEY, params],
    queryFn: () => getAppointmentList(params),
    ...options,
  });
};

export const useGetAppointmentDetailsQuery = (
  params: AppointmentIdPaylod,
  options?: Omit<UseQueryOptions<Appointment, Error>, "queryKey">,
): UseQueryResult<Appointment, Error> => {
  return useQuery<Appointment, Error>({
    queryKey: ["appointmentDetails", params],
    queryFn: () => getAppointmentDetails(params),
    ...options,
  });
};
