import {lazy} from "react";
import {Navigate} from "react-router-dom";
import type {RouteObject} from "react-router-dom";
import Appointments from "../pages/Appointments/AppointmentList";

const Landing = lazy(() => import("../pages/Landing/Landing"));
const Recording = lazy(() => import("../pages/Recording/Recording"));
const Transcription = lazy(() => import("../pages/Transcription/Transcription"));
const AppointmentDetails = lazy(
  () => import("../pages/Appointments/AppointmentDetails/AppointmentDetails"),
);
const PatientDetails = lazy(() => import("../pages/Patients/PatientDetails/PatientDetails"));
const PatientList = lazy(() => import("../pages/Patients/PatientList"));
const HelpAndSupport = lazy(() => import("../pages/HelpAndSupport"));
const SoapNotes = lazy(() => import("../pages/SoapNotes/SoapNotes"));

export const routes: RouteObject[] = [
  {path: "home", element: <Landing />},
  {path: "appointments", element: <Appointments />},
  {path: "recording", element: <Recording />},
  {path: "transcription", element: <Transcription />},
  {path: "appointments", element: <Appointments />},
  {path: "appointment/:id", element: <AppointmentDetails />},
  {path: "patients", element: <PatientList />},
  {path: "patient/:id", element: <PatientDetails />},
  {path: "help-and-support", element: <HelpAndSupport />},
  {path: "soap-notes", element: <SoapNotes />},
  {path: "*", element: <Navigate to={"/home"} />},
];
