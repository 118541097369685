/* eslint-disable react/no-multi-comp */
import {Dispatch, SetStateAction, useState} from "react";
import MyDataTable from "../../../components/common/MyDataTable";
import {extractDateFromTimestamp} from "../../../utils/DateUtils";
import {AppointmentFilters, AppointmentRow} from "./types";
import {SelectedDateType} from "../../../components/common/MyCalendar/types";
import {SEARCH_DEBOUNCE_TIME} from "../../../constants/Values";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/slice/authSlice";
import useDebounce from "../../../hooks/utils/debounce/useDebounce";
import {DataTableFilters, SELECTED_PERIOD} from "../../../components/common/MyDataTable/types";
import {getAppointmentRows, getColumns, getFilters} from "./dataUtils";
import {useGetAppointmentListQuery} from "../../../hooks/appointment/query";
import {HEADER_NAME, INITIAL_PAGE_SIZE} from "../../../components/common/MyDataTable/constants";
import {Appointment} from "../../../api/appointment/appointment.interface";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useCreateRecord} from "../../../hooks/record/mutation";

const SEARCH_PLACEHOLDER = "Search by MRN or Patient Name";

const Appointments = () => {
  const [appointmentFilters, setAppointmentFilters] = useState<AppointmentFilters>({
    selectedPeriod: SELECTED_PERIOD.TODAY,
    selectedDateRange: {modified: false, range: {}} as SelectedDateType,
    searchText: "",
    appointment_date_start: extractDateFromTimestamp(new Date().toISOString(), "yyyy/mm/dd", "-"),
    appointment_date_end: extractDateFromTimestamp(new Date().toISOString(), "yyyy/mm/dd", "-"),
    selectedFilters: {},
    limit: INITIAL_PAGE_SIZE,
    offset: 0,
  });
  const debouncedSearchText = useDebounce(appointmentFilters.searchText, SEARCH_DEBOUNCE_TIME);
  const emr_name = useSelector(selectUser)?.emr_system_details?.emr_name;
  const appointmentsColumns = getColumns(emr_name!);
  const filters = getFilters(emr_name!);
  const {data, isFetching} = useGetAppointmentListQuery({
    offset: appointmentFilters.offset,
    limit: appointmentFilters.limit,
    order_by_desc: false,
    appointment_date_start: appointmentFilters.appointment_date_start,
    appointment_date_end: appointmentFilters.appointment_date_end,
    search: debouncedSearchText,
    ...appointmentFilters.selectedFilters,
  })!;
  const navigate = useNavigate();
  const createRecord = useCreateRecord();
  const [isChangeTemplateModalOpen, setIsChangeTemplateModalOpen] = useState<boolean>(false);

  const rows =
    (getAppointmentRows(
      data?.results ?? [],
      emr_name!,
      navigate,
      isChangeTemplateModalOpen,
      setIsChangeTemplateModalOpen,
      createRecord,
    ) as any) ?? [];
  const onUpdate = (pageSize: number, dateStart: any, dateEnd: any) => {
    setAppointmentFilters(prev => {
      return {
        ...prev,
        limit: pageSize,
        appointment_date_start: dateStart,
        appointment_date_end: dateEnd,
      };
    });
  };
  return (
    <Box>
      <MyDataTable<Appointment, AppointmentRow[]>
        isFetching={isFetching}
        headerName={HEADER_NAME.APPOINTMENTS}
        searchPlaceholder={SEARCH_PLACEHOLDER}
        rowCount={data?.count}
        onUpdate={onUpdate}
        rows={rows}
        columns={appointmentsColumns}
        data={data?.results ?? []}
        dataTableFilters={appointmentFilters}
        setDataTableFilters={setAppointmentFilters as Dispatch<SetStateAction<DataTableFilters>>}
        filters={filters}
        initialState={{
          showFilterIcon: true,
          showPeriodSelect: true,
          showSearchBar: true,
        }}
      />
    </Box>
  );
};

export default Appointments;
