import {MultiSelectValues} from "../MultiSelect/types";
import {SelectedDateType} from "../MyCalendar/types";

export enum SELECTED_PERIOD {
  TODAY = "Today",
  WEEK = "This Week",
  MONTH = "This Month",
  CUSTOM = "Custom",
}

export type DataTableFilters = {
  selectedPeriod: SELECTED_PERIOD;
  selectedDateRange: SelectedDateType;
  searchText: string;
  selectedFilters: MultiSelectValues;
  offset: number;
  limit: number;
};

export type DataTableInitialState = {
  showSearchBar: boolean;
  showFilterIcon: boolean;
  showPeriodSelect: boolean;
};
