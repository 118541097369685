import {RemoveRedEyeOutlined} from "@mui/icons-material";
import colors from "../../../assets/theme/base/colors";
import {
  extractDateFromTimestamp,
  extractTimeFromTimestamp,
  getLocalFormattedDateTime,
} from "../../../utils/DateUtils";
import {invertKeyValuePair} from "../../../utils/GenericUtils";
import {Appointment, APPOINTMENT_STATUS} from "../../../api/appointment/appointment.interface";
import {Filters} from "../../../components/common/MyDataTable/Filter/types";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import TrCell from "../../../components/common/MyDataTable/TrCell";
import {Box, Tooltip, Typography} from "@mui/material";
import {
  getTypographyStyles,
  getTrCellStyles,
  getTypeOfVisitStyles,
  recordBadgeStyles,
  statusTextStyles,
} from "./styles";
import {APPOINTMENT_STATUS_DISPLAY, APPOINTMENT_TYPES} from "../../../constants/Values";
import {AppointmentRow} from "./types";
import MicIcon from "../../../assets/images/icons/MicIcon";
import TickIcon from "../../../assets/images/icons/Tick.svg";
import CrossIcon from "../../../assets/images/icons/CrossIcon.svg";
import blur from "../../../assets/theme/base/blur";
import {EMR_PUSH_STATUS} from "../../../api/common/common.types";
import ChangeTemplateModal from "../../../components/Modals/ChangeTemplateModal/ChangeTemplateModal.index";
import {NavigateFunction} from "react-router-dom";
import {CreateRecordPayload, CreateRecordResponse} from "../../../api/record/record.types";
import {UseMutationResult} from "@tanstack/react-query";

const TypeOfVisitBackground = {
  [APPOINTMENT_TYPES.EMR]: colors.badgeColors.lightBlue.background,
  [APPOINTMENT_TYPES.ADHOC]: colors.badgeColors.lightYellow.background,
  [APPOINTMENT_TYPES.ADHOC_EMR]: colors.badgeColors.lightYellow.background,
};

export const getStatusColor = (status: string): string => {
  switch (status?.toLowerCase()) {
    case APPOINTMENT_STATUS.UPCOMING:
      return colors.primary.main;
    case APPOINTMENT_STATUS.IN_PROGRESS:
      return colors.warning.focus;
    case APPOINTMENT_STATUS.MISSED:
      return colors.error.main;
    default:
      return colors.text.secondary;
  }
};

export const getStatusBadge = (appointment_status: string, emr_push_status: string) => {
  if (
    emr_push_status === EMR_PUSH_STATUS.NONE &&
    (appointment_status === APPOINTMENT_STATUS.IN_PROGRESS ||
      appointment_status === APPOINTMENT_STATUS.UPCOMING)
  ) {
    return <MicIcon color={getStatusColor(appointment_status ?? "")} />;
  }
  if (emr_push_status === EMR_PUSH_STATUS.COMPLETED)
    return <Box data-testId="Tick-Icon" component={"img"} src={TickIcon} />;
  if (emr_push_status === EMR_PUSH_STATUS.FAILED)
    return <Box data-testId="Cross-Icon" component={"img"} src={CrossIcon} />;
  return <></>;
};

export const getBadgeColor = (appointment_status: string): string => {
  switch (appointment_status?.toLowerCase()) {
    case APPOINTMENT_STATUS.UPCOMING:
      return colors.secondary.main;
    case APPOINTMENT_STATUS.IN_PROGRESS:
      return colors.warning.main;
    default:
      return "";
  }
};

const getAppointmentTypeLabels = (emr_name: string) => {
  return {
    [`${emr_name} Appointment`]: APPOINTMENT_TYPES.EMR,
    [`Adhoc - ${emr_name} patients`]: APPOINTMENT_TYPES.ADHOC_EMR,
    [`Adhoc - Non ${emr_name} patients`]: APPOINTMENT_TYPES.ADHOC,
  };
};

export const getAppointmentRows = (
  appointments: Appointment[],
  emr_name: string,
  navigate: NavigateFunction,
  isChangeTemplateModalOpen: boolean,
  setIsChangeTemplateModalOpen: (value: boolean) => void,
  createRecord: UseMutationResult<CreateRecordResponse, unknown, CreateRecordPayload, unknown>,
): AppointmentRow[] => {
  const handleClick = (id: number): void => {
    navigate(`/appointment/${id}`);
  };

  const handleMicClick = (id: string, status: string) => {
    if (status === APPOINTMENT_STATUS.IN_PROGRESS) {
      createRecord.mutate(
        {appointment_id: parseInt(id), start_time: getLocalFormattedDateTime()},
        {
          onSuccess: createRecordResponse => {
            navigate(`/recording`, {state: {recordId: createRecordResponse.record_id}});
          },
        },
      );
    } else {
      setIsChangeTemplateModalOpen(true);
    }
  };

  const onTemplateModalClose = (id: number) => {
    setIsChangeTemplateModalOpen(false);
    navigate(`/soap-notes/${id}`);
  };

  return appointments.map(
    ({
      patient,
      appointment_name,
      appointment_time,
      appointment_type,
      id,
      reason_for_visit,
      appointment_status,
      emr_push_status,
    }) => {
      return {
        id,
        patientName: patient ? `${patient.first_name} ${patient.last_name}` : appointment_name,
        date: extractDateFromTimestamp(appointment_time),
        time: extractTimeFromTimestamp(appointment_time),
        typeOfVisit: invertKeyValuePair(getAppointmentTypeLabels(emr_name))[appointment_type],
        reasonForVisit: reason_for_visit,
        details: (
          <RemoveRedEyeOutlined
            onClick={() => {
              handleClick(id);
            }}
            sx={{
              color: colors.text.tertiary + blur["80%"],
              cursor: "pointer",
              height: "16px",
              width: "16px",
            }}
          />
        ),
        status: {
          text: (
            <Typography
              sx={{color: getStatusColor(appointment_status ?? ""), ...statusTextStyles()}}
              variant="h6">
              {APPOINTMENT_STATUS_DISPLAY[appointment_status || APPOINTMENT_STATUS.NONE]}

              <Box
                onClick={() => {
                  handleMicClick(id.toString(), appointment_status ?? "");
                }}
                sx={{
                  backgroundColor: getBadgeColor(appointment_status ?? ""),
                  ...recordBadgeStyles(),
                }}>
                <Tooltip
                  title={`Notes are ${emr_push_status !== EMR_PUSH_STATUS.COMPLETED ? "not" : ""} pushed to EMR`}>
                  {getStatusBadge(appointment_status ?? "", emr_push_status ?? "")}
                </Tooltip>
              </Box>

              <ChangeTemplateModal
                isOpen={isChangeTemplateModalOpen}
                appointmentId={id.toString() || "-1"}
                isUpcoming={true}
                onTemplateModalClose={() => onTemplateModalClose(id)}
              />
            </Typography>
          ),
          color: getStatusColor(appointment_status ?? ""),
        },
      };
    },
  );
};

export const getFilters = (emr_name: string): Filters => {
  return [
    {
      label: "Type of Visit",
      name: "appointment_type",
      options: Object.keys(getAppointmentTypeLabels(emr_name)).map(ele => {
        return {value: getAppointmentTypeLabels(emr_name)[ele], label: ele};
      }),
    },
    {
      label: "Status",
      name: "status",
      options: Object.keys(APPOINTMENT_STATUS).map(ele => {
        return {
          value: APPOINTMENT_STATUS[ele as keyof typeof APPOINTMENT_STATUS],
          label: ele,
        };
      }),
    },
  ];
};

export const getColumns = (emr_name: string): GridColDef[] => [
  {
    field: "patientName",
    headerName: "Patient Name",
    minWidth: 150,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <TrCell>
        <Typography sx={getTypographyStyles("grey.700", 600)}>{params.value}</Typography>
      </TrCell>
    ),
  },
  {
    field: "date",
    headerName: "Date",
    minWidth: 130,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <TrCell sx={getTrCellStyles()}>
        <Typography sx={getTypographyStyles()}>{params.value}</Typography>
      </TrCell>
    ),
  },
  {
    field: "time",
    headerName: "Time",
    minWidth: 130,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <TrCell sx={getTrCellStyles()}>
        <Typography sx={getTypographyStyles()}>{params.value}</Typography>
      </TrCell>
    ),
  },
  {
    field: "typeOfVisit",
    headerName: "Type of Visit",
    minWidth: 200,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <TrCell sx={getTrCellStyles()}>
        <Typography
          sx={getTypeOfVisitStyles(
            TypeOfVisitBackground[getAppointmentTypeLabels(emr_name)[params.value]],
          )}>
          {params.value}
        </Typography>
      </TrCell>
    ),
  },
  {
    field: "reasonForVisit",
    headerName: "Reason for Visit",
    sortable: false,
    minWidth: 160,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <TrCell
        sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <Typography sx={getTypographyStyles()}>{params.value ?? "-"}</Typography>
      </TrCell>
    ),
  },
  {
    field: "details",
    headerName: "Details",
    sortable: false,
    headerAlign: "center",
    minWidth: 100,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        {params.value}
      </Box>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    minWidth: 160,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => {
      const {text, color = "grey"} = params.value;
      return (
        <TrCell>
          <Typography sx={getTypographyStyles(color, 600)}>{text}</Typography>
        </TrCell>
      );
    },
  },
];
